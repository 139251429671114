.pagination-select {
	:global {
		.ant-select-selector {
			border-radius: 4px;
		}
	}
}
.pagination {
	:global {
		.ant-pagination-item {
			border-radius: 2px !important;
			a {
				font-size: 14px;
			}
		}
		.ant-pagination-item-active {
			background-color: #006ceb;
			a {
				color: white !important;
			}
		}
		.ant-pagination-item-ellipsis {
			color: black !important;
		}
	}
}
