.sp-range-picker {
	:global {
		.ant-picker-range-arrow {
			display: none !important;
		}
		.ant-picker-panel-layout,
		.ant-picker-panel-container {
			box-shadow: none !important;
		}
	}
}
