.business-unit-filter {
	:global {
		.ant-cascader-menu-item-content {
			text-overflow: ellipsis;
			overflow: hidden;
		}
		.ant-select-selector {
			border-radius: 8px;
		}
	}
}
