.sp-table {
	.ant-table-thead {
		.ant-table-column-title {
			color: #67727e;
		}
		.ant-table-cell {
			background-color: #fff;
			color: #67727e;
			padding: 14px 16px;

			&::before {
				display: none;
			}
		}
		.ant-table-column-sort {
			background: none !important;
		}
	}
	.ant-table-tbody {
		.ant-table-row:nth-child(even) {
			background-color: #f6f6f6;

			.ant-table-cell-fix-right {
				background-color: #f6f6f6;
			}
		}
		.ant-table-cell {
			padding: 14px 16px;
			border-bottom: 0;
		}
		.ant-table-column-sort {
			background: none;
		}
	}
}
.ant-cascader-dropdown .ant-cascader-menu {
	height: fit-content !important;
}
.ant-picker-dropdown {
	z-index: 10;
}
