.pricing-table {
	:global {
		.ant-table-thead {
			.ant-table-cell {
				background-color: white;
			}
		}
		.ant-table-cell {
			vertical-align: top;
			border-right: 0 !important;
			&:last-child {
				border-right: 1px solid #e8e8e8 !important;
			}
		}
	}
}
