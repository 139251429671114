.org-service-table {
	:global {
		.ant-table-thead {
			tr > th:not(:last-child) {
				border-right: none !important;
			}
			.ant-checkbox-indeterminate .ant-checkbox-inner:after {
				height: 2px !important;
				background: white;
			}
			.ant-checkbox-indeterminate .ant-checkbox-inner {
				background: #1677ff;
			}
		}
		.ant-table-tbody {
			tr > td:not(:last-child) {
				border-right: none !important;
			}
			.ant-checkbox-wrapper {
				display: inline-flex !important;
			}
		}
		.ant-checkbox-disabled .ant-checkbox-inner {
			border: 1px solid #edf0f5;
			background-color: #fff;
		}
	}
	.highlighted tr:nth-child(2) td {
		background-color: #ebf3fd;
	}
}
