.sp-checkbox {
	:global {
		.ant-checkbox-inner {
			width: 24px;
			height: 24px;
		}
		.ant-checkbox-inner:after {
			inset-inline-start: 30%;
		}
		.ant-checkbox-indeterminate .ant-checkbox-inner:after {
			height: 2px !important;
			background: white;
			inset-inline-start: 50%;
		}
		.ant-checkbox-indeterminate .ant-checkbox-inner {
			background: #1677ff;
		}
	}
}
