.list {
	:global {
		.ant-input,
		.ant-input-affix-wrapper {
			border: 1px solid #bac3d1;
			padding: 12px 16px;
		}
		.ant-form-item-explain-error {
			font-size: 12px;
		}
		.ant-select .ant-select-selector {
			border-radius: 8px;
			border: 1px solid #bac3d1;
		}
	}
}
