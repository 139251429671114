.redo-loading {
	animation: rotate 1s linear infinite; /* Adjust the duration as needed */
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
