.table {
	:global {
		.ant-table-thead {
			tr > th:not(:last-child) {
				border-right: none !important;
			}
			tr > th {
				background: transparent;
				padding: 12px 16px;
				border-bottom: 1px solid #edf0f5;
			}
		}
		.ant-table-tbody {
			tr > td:not(:last-child) {
				border-right: none !important;
			}
			tr:hover {
				.ant-table-cell {
					background: #ebf3fd !important;
				}
			}
		}
	}
}
