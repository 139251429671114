.reminder-settings {
	:global {
		.ant-picker-ok {
			.ant-btn-primary:not(:disabled) {
				background-color: #006ceb;
			}
		}
		.ant-switch:not(.ant-switch-checked) {
			background-color: #a4aebd;
		}
	}
}
