.billing-status-filter {
	:global {
		.ant-checkbox-indeterminate .ant-checkbox-inner:after {
			height: 2px !important;
			background: white;
		}
		.ant-checkbox-indeterminate .ant-checkbox-inner {
			background: #1677ff;
		}
	}
}
