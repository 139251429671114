@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,
body {
	height: 100%;
}
#root {
	height: 100%;
}
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
.ant-popover-inner {
	padding: 0 !important;
	box-shadow: 0px 4px 16px 0px #00000014 !important;
}
.ant-popover-arrow {
	display: none !important;
}
.ant-form-item-explain-error {
	margin-top: 4px;
}
.no-scrollbar::-webkit-scrollbar {
	display: none;
}
.no-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
}
::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	opacity: 0;
}
:hover::-webkit-scrollbar {
	opacity: 1;
}
::-webkit-scrollbar-thumb {
	background: #d5dbe5;
	border-radius: 8px;
}
.notif-toast-notice-content {
	padding: 0 !important;
	box-shadow: none !important;
}
.notif-toast {
	z-index: 1050;
}
.custom-monthpicker .ant-picker-input {
	flex-flow: row-reverse;
	gap: 8px;
	color: #1b232d;
}
.custom-monthpicker > .ant-picker-input > .ant-picker-suffix {
	color: #1b232d;
	margin-inline-start: 0;
}
.custom-monthpicker {
	box-shadow: none;
}
.custom-monthpicker
	> .ant-picker-input
	> .ant-picker-suffix
	> .anticon-calendar {
	font-size: 20px;
}
.confirm-modal {
	width: 500px !important;
}
.confirm-modal .ant-modal-confirm-title {
	font-weight: 700 !important;
	font-size: 20px !important;
}
.confirm-modal .ant-modal-confirm-body {
	align-items: flex-start;
}
.confirm-modal .anticon {
	margin-top: 4px;
}
.confirm-modal .ant-modal-confirm-btns {
	display: flex;
	justify-content: flex-end;
}
.confirm-modal .ant-modal-confirm-btns .ant-btn-primary,
.confirm-modal .ant-modal-confirm-btns .ant-btn-default {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-weight: 700;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	border-radius: 0.5rem;
	transition-property: none;
	height: 32px;
}
.confirm-modal .ant-modal-confirm-btns .ant-btn-primary {
	background-color: #006ceb;
}
.ant-checkbox .ant-checkbox-inner {
	border: 1.5px solid #bac3d1;
}
