.table {
	:global {
		.ant-table-tbody {
			.ant-table-cell {
				vertical-align: top;
			}
			.ant-table-row {
				&:hover {
					td {
						background-color: #e6f0fd !important;
					}
				}
			}
		}
	}
}
