.invoice-table {
	tr > th {
		padding: 12px 16px !important;
	}
	.ant-table-thead {
		tr > th:not(:last-child) {
			border-right: none !important;
		}
		.ant-table-cell {
			background: white;
		}
	}
	.ant-table {
		margin-inline: 0px !important;
	}
	.ant-table-tbody {
		.ant-table-cell {
			vertical-align: top;
		}
		tr.ant-table-row.ant-table-row-level-0 > td:not(:last-child) {
			border-right: none !important;
		}

		tr.ant-table-row.ant-table-row-level-0 {
			background: #f9fafc;
		}

		tr.ant-table-expanded-row.ant-table-expanded-row-level-1 > td {
			padding: 16px 0px !important;
		}
	}
	.summary-table {
		.ant-table-summary {
			tr > td {
				background: #f9fafc;
			}
			tr > td:not(:last-child) {
				border-right: none !important;
			}
		}
		.ant-table-cell {
			background: white;
		}
	}
	.service-table {
		tfoot {
			tr > td {
				background: #f9fafc;
			}
			tr > td:not(:last-child) {
				border-right: none !important;
			}
		}
	}
}
.sub-table {
	.ant-table-tbody {
		tr.ant-table-row.ant-table-row-level-0 {
			background: white !important;
		}
	}
	tfoot {
		tr > td {
			background: white !important;
		}
	}
}

@media print {
	@page {
		margin: 0;
	}

	body * {
		visibility: hidden;
	}

	#print,
	#print * {
		visibility: visible;
	}

	#print {
		position: absolute;
		left: 0;
		top: 0;
		padding: 36px;
	}

	#not-print {
		display: none;
	}
}
